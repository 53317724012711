<template>
  <el-row id="login" type="flex" justify="center" align="middle">
    <el-col :xs="20" :sm="12" :md="6" :lg="4">
      <el-card v-loading="wxacode.loading" :body-style="{ padding: '15px' }">
        <el-image v-loading="wxacode.scanned" :src="'data:image/jpeg;base64,'+ wxacode.base64" />
        <div class="title">{{ wxacode.scanned ? '微信扫码成功' : '微信“扫一扫”登录' }}</div>
        <div class="hint">{{ wxacode.scanned ? '请在手机上点击“确认登录”' : '请使用微信直接扫码并确认登录' }}</div>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
import wxacode_placeholder from './assets/ph-wxacode'
import consumer from './channels/consumer'
import './assets/admin-bg.scss'

// For chromium before version 87
if (!window.cookieStore) {
  window.cookieStore = {
    set: (key, value) => document.cookie = `${key}=${value}`,
    delete: (key) => document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 GMT`
  }
}

export default {
  data() {
    return {
      wxacode: {
        loading: true,
        scanned: false,
        base64: wxacode_placeholder.base64
      },
      cable: null,
      user: null
    }
  },

  mounted() {
    sessionStorage.clear()
    cookieStore.delete('auth_token')

    // Create active cable connection
    new Promise((resolve) => {
      let self = this
      consumer.subscriptions.create('WebChannel', {
        connected: function () { resolve(self.cable = this) }
      })
    })

    // Fetch authentication wxacode
    .then(() => new Promise((resolve) => {
      this.cable.received = (wxacode) => {
        this.wxacode.loading = false
        this.wxacode.base64 = wxacode
        resolve()
      }
      this.cable.perform('wxacode')
    }))

    // Wait for weixin scanning
    .then(() => new Promise((resolve) => {
      this.cable.received = (user) => {
        if (user) {
          resolve(this.user = user)
        } else {
          this.wxacode.scanned = true
        }
      }
    }))

    // Redirect to the stats page
    .then((user) => {
      sessionStorage.setItem('user', JSON.stringify(user))
      cookieStore.set('auth_token', user.auth_token)
      return location = '/stats'
    })
  }
}
</script>

<style>
html, body, #login {
  width: 100% !important;
  height: 100% !important;
}

#login .el-image {
  width: 100%;
}

#login .title {
  margin-top: 10px;
}

#login .hint {
  font-size: 0.8em;
  color: #999;
}
</style>
